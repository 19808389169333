import React, { useEffect, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BookingStudent from './BookingStudent';
import OverViewStudent from './OverViewStudent';
import './ViewProfileStudentStyle.css';
import { adminSliceData, clearState, getSpecificMenteeDetails } from "../../../reducer/adminSlice";
import { useNavigate } from "react-router-dom";
import ErrorToast from "../../../components/Toast/ErrorToast";
import PageLoader from "../../../components/PageLoader/PageLoader";
import Button from '../../../components/Button/Button';

const ViewProfileStudent = () => {
    const navigate = useNavigate();
    const [tabToggler, setTabToggler] = useState(0);
    //Toast states
    const [isShowError, setIsShowError] = useState(false);
    const [errMessage, setErrMessage] = useState("");
  
    const { id } = useParams();
    const dispatch = useDispatch();
  
    const { isFetching, specificMenteeDetails, isError, errorMessage } =
      useSelector(adminSliceData);
  
    useEffect(() => {
      dispatch(getSpecificMenteeDetails(id));
    }, []);
  
    useEffect(() => {
      if (isError) {
        setIsShowError(true);
        setErrMessage(errorMessage);
        dispatch(clearState())
      }
    }, [isError]);
    return (
        <>
        {isFetching && <PageLoader />}
         <ErrorToast
            show={isShowError}
            setShow={() => setIsShowError(false)}
            message={errMessage}
          />
            <div className="headerSmall withButton">
              <p className="HighlightHeading"><span className="parent"  onClick={() => navigate("/allStudent")}>All Students </span>/ View Profile</p>
              <Button
            className="backbtn"
            src={"/assets/icons/backbtn.svg"}
            onClick={() => navigate(-1) }
          />
            </div>
    
            <div className="mentorProfileContainer mt-10">
                <div className="mentorProfile">
                    <img src={ specificMenteeDetails?.menteeDetails?.profileImg
               ?specificMenteeDetails?.menteeDetails?.profileImg
               :  "/assets/img/demoUser.png"
           } 
                    
                    alt="" className="mentorImg"/>
                    <div className="mentorDetailsProfile">
                        <p className="mentorNameHeading">
                        {specificMenteeDetails?.menteeDetails?.firstName ? specificMenteeDetails?.menteeDetails?.firstName : "---"}  {specificMenteeDetails?.menteeDetails?.lastName? specificMenteeDetails?.menteeDetails?.lastName :"---"}
                            <div className="linkdinBtn" onClick={() => window.open("https://"+specificMenteeDetails?.menteeDetails?.linkedin)}>
                                Linkedin
                                <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M12.8125 13.75H2.1875"
                                    stroke="#3397EF"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    />
                                    <path
                                    d="M11.875 2.1875L3.125 10.9375"
                                    stroke="#3397EF"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    />
                                    <path
                                    d="M11.875 8.60625V2.1875H5.45625"
                                    stroke="#3397EF"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        </p>
                        <p className="designation">{specificMenteeDetails?.menteeDetails?.mentorshipProfession?.designation ? `${specificMenteeDetails?.menteeDetails?.mentorshipProfession?.designation} at ` : ""} {specificMenteeDetails?.menteeDetails?.mentorshipProfession?.company ? specificMenteeDetails?.menteeDetails?.mentorshipProfession?.company :""}</p>
                    </div>
                </div>
                <div className="tabsContainer">
    
                    <div className={`tab ${tabToggler === 0 && "active"}`}
                    onClick={() => setTabToggler(0)}>
                    Overview
                    </div>
    
                    {/* <div className={`tab ${tabToggler === 1 && "active"}`}
                    onClick={() => setTabToggler(1)}>
                    Bookings & Feedback
                    </div> */}
    
                </div>
                <div className="tabContentOuter mentorRequest mt-30">
                    <OverViewStudent isActive={tabToggler === 0} data={specificMenteeDetails?.menteeDetails}/>
                    {/* <BookingStudent isActive={tabToggler === 1}/> */}
                </div>
                </div>
    
    
        </>
      )
}

export default ViewProfileStudent