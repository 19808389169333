import React, { useState } from "react";
import "./MeetingSchedulerPopoupStyle.css";
import { Calendar } from "react-calendar";
import SlotSelector from "../../SlotSelector/SlotSelector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MeetingSchedulerPopoup = ({value, setValue, date, setDate, slots, slotId, setSlotId, onClick, DateArray }) => {

  return (
    <div className={`popupOuter meetingPopup ${value?"active":""}`}>
      <div className="popupContainer">
        <div className="popupHeader">
          <p className="popupTitle">Select a Date & Time</p>
          <img src="/assets/icons/Close.svg" className="closeWhite" alt="" onClick={()=>{setValue(false)}}/>
        </div>
        <div className="popupBody">
          <div className="schedulerContainer">
            {/* <div className="calendarOuter">
              <Calendar minDate={new Date()} value={date} onChange={e => setDate(e)} />
            </div> */}
             <DatePicker
            // includeDates={[new Date('2023-02-08'), new Date('2023-02-16'),new Date('2023-02-17'),new Date('2023-02-28'), new Date('2023-02-27'), new Date('2023-03-27')]} 
            includeDates= { (DateArray?.length > 0 ? DateArray?.map((item)=>(new Date(item))): [new Date()]) }
            onChange={(e)=> setDate(e)}
            showIcon 
            open
            selected={date}
            />
            <div className="selectInterviewContainer">
              <p className="month">Available Slot</p>
              <div className="slotsOuter">
                  
                  {slots?.length > 0 ? slots?.map((item, index) => (
                    <SlotSelector text={item.time} id={`slot${index}`} name={'slotter'} checked={slotId === item.id} onChange={() => slotId === item.id ? setSlotId("") : setSlotId(item.id)} value={'value'} onClick={onClick} />
                  ))
                :
                "No slots available. Try another date"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingSchedulerPopoup;
