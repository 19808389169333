import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { adminSliceData, clearState, getAllMentee, getMenteePastCalls } from "../../../reducer/adminSlice";
import ErrorToast from "../../../components/Toast/ErrorToast";
import PageLoader from "../../../components/PageLoader/PageLoader";
import CallPopup from "../../../components/popups/CallPopup/CallPopup";

const AllStudents = () => {
  const [value, setValue] = useState(false);

  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [pastCallData, setPastCallData]=useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isFetching, allMentees, menteePastCall, isError, errorMessage } =
    useSelector(adminSliceData);

  const handleClickPastCall=(id)=>{
   console.log("iiiddd", id);
   setValue(true);
   dispatch(getMenteePastCalls(id))
   
  }  

  useEffect(()=>{
    setPastCallData(menteePastCall)
  },[menteePastCall])

  useEffect(() => {
    dispatch(getAllMentee());
  },[]);

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage)
      dispatch(clearState());
    }
  }, [isError]);

  return (
    <>
    {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
     {value && <CallPopup  
      setValue={setValue}
      value={value}
      pastCallData={pastCallData}
      />}
      <div className="headerSmall">
        <p className="HighlightHeading">All Students</p>
      </div>

      <div className="tableContainer mt-10">
        <table className="studentTable" cellSpacing={0} cellPadding={0}>
          <thead>
            <tr>
              <th>
                <div className="mentorProfileTh">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.91665 3.125C6.19076 3.125 4.79165 4.52411 4.79165 6.25C4.79165 7.97589 6.19076 9.375 7.91665 9.375C9.64254 9.375 11.0417 7.97589 11.0417 6.25C11.0417 4.52411 9.64254 3.125 7.91665 3.125ZM3.54165 6.25C3.54165 3.83375 5.50041 1.875 7.91665 1.875C10.3329 1.875 12.2917 3.83375 12.2917 6.25C12.2917 8.66625 10.3329 10.625 7.91665 10.625C5.50041 10.625 3.54165 8.66625 3.54165 6.25ZM12.7736 2.61165C12.9272 2.30254 13.3024 2.17649 13.6115 2.3301C15.0505 3.04525 16.0417 4.5313 16.0417 6.25C16.0417 7.9687 15.0505 9.45475 13.6115 10.1699C13.3024 10.3235 12.9272 10.1975 12.7736 9.88835C12.62 9.57923 12.7461 9.20412 13.0552 9.0505C14.0857 8.5384 14.7917 7.47607 14.7917 6.25C14.7917 5.02393 14.0857 3.9616 13.0552 3.4495C12.7461 3.29588 12.62 2.92077 12.7736 2.61165ZM7.91665 13.9583C5.7235 13.9583 3.68937 15.1039 2.15811 17.0528C1.94485 17.3242 1.55194 17.3714 1.28052 17.1581C1.0091 16.9449 0.961944 16.552 1.1752 16.2805C2.88808 14.1004 5.25844 12.7083 7.91665 12.7083C10.5749 12.7083 12.9452 14.1004 14.6581 16.2805C14.8714 16.552 14.8242 16.9449 14.5528 17.1581C14.2814 17.3714 13.8885 17.3242 13.6752 17.0528C12.1439 15.1039 10.1098 13.9583 7.91665 13.9583ZM14.4306 13.7143C14.5729 13.3998 14.9432 13.2603 15.2577 13.4026C16.6212 14.0196 17.8322 15.0173 18.8248 16.2805C19.038 16.552 18.9909 16.9449 18.7195 17.1581C18.448 17.3714 18.0551 17.3242 17.8419 17.0528C16.9553 15.9244 15.8978 15.0643 14.7423 14.5414C14.4278 14.3991 14.2883 14.0288 14.4306 13.7143Z"
                      fill="#D4D4D4"
                    />
                  </svg>
                  Student Profile
                </div>
              </th>
              <th>Area of Interest</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>View Slots</th>
            </tr>
          </thead>
          <tbody>
            {allMentees?.length > 0
              ? allMentees?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div
                        className="mentorContent"
                        onClick={() => navigate("/viewStudent/"+item.id)}
                      >
                        <img
                          className="profileImg"
                          src={
                            item?.profileImg
                              ? item?.profileImg
                              : "./assets/img/profiledefault.png"
                          }
                          alt=""
                        />
                        <div className="mentorDetails">
                          <p className="mentorName">
                            {item?.firstName + " " + item?.lastName}
                          </p>
                          <p className="mentorDetail mt-5">
                            {item?.menteeType}
                          </p>
                        </div>
                      </div>
                    </td>
                    {/* <td>{item.GetPrepTopics?.length > 0 ? item.GetPrepTopics[0]?.Topic : "---"}</td> */}
                    <td>{ item.mentorshipTopics?.length > 0 ? item?.mentorshipTopics?.map((item,index)=>((!index ? "" :",")+ item?.Topic)): "---"}</td>
                    <td>{ item?.officialEmail ?item?.officialEmail : "---"}</td>
                    <td>{item?.mobileNumber ?item?.mobileNumber: "---"}</td>
                    <td>
                      <Button
                        className={"imgButton GreenSlotButton"}
                        src={"/assets/icons/dropLow.svg"}
                        name={"Past Call"}
                        onClick={() => handleClickPastCall(item?.id)}
                      />
                    </td>
                  </tr>
                ))
              : "No data available"}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AllStudents;
