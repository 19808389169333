import React from 'react'
import { Calendar } from "react-calendar";
// import DatePicker from 'react-datepicker';
import SlotSelector from "../../../components/SlotSelector/SlotSelector"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';


function WeeklySlots({ isActive, mentorAvailableSlots, date, setDate, DateArray}) {
  

    return (
        <div className={`tabContent ${isActive && 'active'}`}>

        <div className="dateRangeWrapper weeklySlotWrapper newDateSelector mt-10">

             <DatePicker
            // includeDates={[new Date('2023-02-08'), new Date('2023-02-16'),new Date('2023-02-17'),new Date('2023-02-28'), new Date('2023-02-27'), new Date('2023-03-27')]} 
            includeDates= { (DateArray?.length > 0 ? DateArray?.map((item)=>(new Date(item))): [new Date()]) }
            onChange={(e)=> setDate(e)}
            showIcon 
            open
            selected={date}
            /> 
              
            <div className="selectInterviewContainer">
              <p className="month">Slot Timing</p>
              <div className="slotsOuter">
                {mentorAvailableSlots?.length > 0
                  ? mentorAvailableSlots?.map((item, index) => (
                      <SlotSelector
                      text={item.time}
                      id={"slot" + index}
                      name={"slotter"}
                      value={"value"}
                      disabled
                      />
                      ))
                      : "No slot added"}
              </div>
            </div>

            </div>

        </div>

    )
}

export default WeeklySlots