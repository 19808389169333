import React, { useEffect, useState } from "react";
import Booking from "./Booking";
import OverView from "./OverView";
import WeeklySlots from "./WeeklySlots";
import "./ViewProfileStyle.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminSliceData, clearState, getSpecificMentorDetails,getMentorInterviewSlots, getMentorAllSlots } from "../../../reducer/adminSlice";
import ErrorToast from "../../../components/Toast/ErrorToast";
import PageLoader from "../../../components/PageLoader/PageLoader";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import moment from "moment";

const ViewProfile = () => {
  const navigate = useNavigate();
  const [tabToggler, setTabToggler] = useState(0);
  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();

  const { isFetching, specificMentorDetails, mentorAvailableSlots, isError, errorMessage,isFetchingMentorData,mentorAllSlotsData } =
    useSelector(adminSliceData);

  useEffect(() => {
    dispatch(clearState());
    dispatch(getSpecificMentorDetails(id));
  }, []);

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState())
    }
  }, [isError]);

  // slot_booking//////////////////////////////////
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    let obj ={
      date:(moment(date).format("YYYY-MM-DD")),
      mentorId:id
    }
   
    if(tabToggler===1){
    dispatch(getMentorInterviewSlots(obj));
    dispatch(getMentorAllSlots(id))
  }
  }, [date,tabToggler]);

  // calender date pre-selected../////////
  
  const DateArray = (mentorAllSlotsData?.length > 0 && mentorAllSlotsData?.map((item)=>(item?.startTime.slice(0,10)))) 
  console.log(DateArray) ;

  // let DateArray =[ 
  //                 '2023-03-10', 
  //                 '2023-03-2',  
  //                 '2023-03-27' 
  //               ]            
  return (
    <>
    {(isFetchingMentorData || isFetching) && <PageLoader />}
    <ErrorToast
            show={isShowError}
            setShow={() => setIsShowError(false)}
            message={errMessage}
          />
      <div className="headerSmall withButton">
        <p className="HighlightHeading">
          <span className="parent"  onClick={() => navigate("/allMentor")}>All Mentors </span>/ View Profile
        </p>
        <Button
            className="backbtn"
            src={"/assets/icons/backbtn.svg"}
            onClick={() => navigate(-1) }
          />
      </div>

      <div className="mentorProfileContainer mt-10">
        <div className="mentorProfile">
          <img
           src={
            specificMentorDetails?.mentorDetails?.profilePicUrl
               ?specificMentorDetails?.mentorDetails?.profilePicUrl
               :  "/assets/img/demoUser.png"
           }
            alt=""
            className="mentorImg"
          />
          <div className="mentorDetailsProfile">
            <p className="mentorNameHeading">
              {specificMentorDetails?.mentorDetails?.firstName ?specificMentorDetails?.mentorDetails?.firstName :"---"} {specificMentorDetails?.mentorDetails?.lastName ?specificMentorDetails?.mentorDetails?.lastName :""}
              <div
                className="linkdinBtn"
                onClick={() => window.open(specificMentorDetails?.mentorDetails?.linkedin ? specificMentorDetails?.mentorDetails?.linkedin : "")}
              >
                Linkedin
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8125 13.75H2.1875"
                    stroke="#3397EF"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.875 2.1875L3.125 10.9375"
                    stroke="#3397EF"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.875 8.60625V2.1875H5.45625"
                    stroke="#3397EF"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </p>
            <p className="designation">{specificMentorDetails?.mentorDetails?.mentorshipProfession?.designation} at {specificMentorDetails?.mentorDetails?.mentorshipProfession?.company}</p>
          </div>
        </div>
        <div className="tabsContainer">
          <div
            className={`tab ${tabToggler === 0 && "active"}`}
            onClick={() => {setTabToggler(0); setDate(new Date)}}
          >
            Overview
          </div>

          <div
            className={`tab ${tabToggler === 1 && "active"}`}
            onClick={() => setTabToggler(1)}
          >
            Weekly Slots
          </div>

          {/* <div
            className={`tab ${tabToggler === 2 && "active"}`}
            onClick={() => setTabToggler(2)}
          >
            Bookings & Feedback
          </div> */}
        </div>
        <div className="tabContentOuter mentorRequest mt-30">
          <OverView data={specificMentorDetails?.mentorDetails} isActive={tabToggler === 0} />
          <WeeklySlots isActive={tabToggler === 1} mentorAvailableSlots={mentorAvailableSlots} date={date} setDate={setDate} DateArray={DateArray}/>
          {/* <Booking data={specificMentorDetails?.pastCalls} isActive={tabToggler === 2} /> */}
        </div>
      </div>
    </>
  );
};

export default ViewProfile;
