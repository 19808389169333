import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import TimePicker from "rc-time-picker-date-fns";
import "rc-time-picker-date-fns/assets/index.css";
import Button from "../../../components/Button/Button";
import InputField from "../../../components/InputField/InputField";
import ErrorToast from "../../../components/Toast/ErrorToast";
import { addAdminAvailableSlots, clearState, mentorSliceData } from "../../../reducer/mentorSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../components/PageLoader/PageLoader";

function EditSlots({ isActive }) {

  const [selectedWeekDays, setSelectedWeekDays] = useState([]);
  const [dateRange, setDateRange] = useState({
    from: "",
    to: ""
})

  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const dispatch = useDispatch(); 
  const navigate = useNavigate();

  const now = new Date(moment().hour(0).minute(0));
  const format = "h:mm a";
  const daysInAWeek = ["sun", "mon", "tue", "wed", "thru", "fri", "sat"];

  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(mentorSliceData);

  const [timeRangeCount, setTimeRangeCount] = useState({
    mon: [0],
    tue: [0],
    wed: [0],
    thru: [0],
    fri: [0],
    sat: [0],
    sun: [0],
  });

  const [interviewTime, setInterviewTime] = useState({
    sun: [{ from: now, to: now }],
    mon: [{ from: now, to: now }],
    tue: [{ from: now, to: now }],
    wed: [{ from: now, to: now }],
    thru: [{ from: now, to: now }],
    fri: [{ from: now, to: now }],
    sat: [{ from: now, to: now }],
  });

  const handleCheckboxToggle = (day) => {
    if (selectedWeekDays.includes(day))
      setSelectedWeekDays((prev) => prev.filter((item) => item !== day));
    else setSelectedWeekDays((prev) => [...prev, day]);
  };

  const handleTimeFromChange = (e, type, i) => {
    const value = e;
    const timeSlot = interviewTime[type];
    timeSlot[i].from = value;
    setInterviewTime((prev) => ({ ...prev, [type]: [...timeSlot] }));
  };

  const handleTimeToChange = (e, type, i) => {
    const value = e;
    const timeSlot = interviewTime[type];
    timeSlot[i].to = value;
    setInterviewTime((prev) => ({ ...prev, [type]: [...timeSlot] }));
  };

  const handleTimeSlotRemove = (type, index) => {
    if (interviewTime[type].length === 1) {
      setSelectedWeekDays((prev) => prev.filter((day) => day !== type));
    } else {
      const timeSlots = interviewTime[type];
      const timeSlot = timeRangeCount[type];
      timeSlot.splice(index, 1);
      timeSlots.splice(index, 1);
      setTimeRangeCount((prev) => ({ ...prev, [type]: [...timeSlot] }));
      setInterviewTime((prev) => ({ ...prev, [type]: [...timeSlots] }));
    }
  };

  const validate = () => {
    if (dateRange.from === "" || dateRange.to === "") return "date";
    if (selectedWeekDays.length === 0) return "noSlot";
    let check = "";
    selectedWeekDays.forEach((day) => {
      interviewTime[day].forEach((time) => {
        if (
          new Date(time.from).getTime() + 1200000 >
          new Date(time.to).getTime()
        ) {
          check = "time";
        }
      });
    });
    return check;
  };

  const handleSave = () => {
    if (validate()) {
      if (validate() === "date") {
        setIsShowError(true);
        setErrMessage("Please select a date range!");
      }
      if (validate() === "time") {
        setIsShowError(true);
        setErrMessage("Time slots should have atleast 20 min difference!");
      }
      if (validate() === "noSlot") {
        setIsShowError(true);
        setErrMessage("Please select a day for availablity!");
      }
    } else {
      let obj;
      obj = interviewTime;
      for (const day in interviewTime) {
        if (!selectedWeekDays.includes(day)) {
          obj = { ...obj, [day]: [] };
        } else {
          const din = obj[day];
          din.map((time) => {
            time.from = moment(time.from).format("HH:mm:ss");
            time.to = moment(time.to).format("HH:mm:ss");
          });
          obj = { ...obj, [day]: [...din] };
        }
      }
      const object = {
        dateRange: dateRange,
        interviewTime: interviewTime,
      };
      dispatch(addAdminAvailableSlots(object));
      setTimeRangeCount({
        mon: [0],
        tue: [0],
        wed: [0],
        thru: [0],
        fri: [0],
        sat: [0],
        sun: [0],
      });
      setInterviewTime({
        sun: [{ from: now, to: now }],
        mon: [{ from: now, to: now }],
        tue: [{ from: now, to: now }],
        wed: [{ from: now, to: now }],
        thru: [{ from: now, to: now }],
        fri: [{ from: now, to: now }],
        sat: [{ from: now, to: now }],
      });
    }
  };

  useEffect(() => {
    const InBetweenDays = Math.abs((new Date(dateRange.from).getTime() - new Date(dateRange.to).getTime()) / (1000 * 60 * 60 * 24));
    if (dateRange.to !== "") {
        if (InBetweenDays <= 6) {
            setSelectedWeekDays([]);
            for (let i = 0; i <= InBetweenDays; i++) {
                if (i > 6) break;
                const day = new Date(moment(dateRange.from).add(i, "day")).getDay();
                setSelectedWeekDays(prev => [...prev, daysInAWeek[day]]);
            }
        }
        else {
            setSelectedWeekDays(daysInAWeek);
        }
    }
}, [dateRange]);

  useEffect(() => {
    if(isSuccess) {
      navigate("/mentorSlots");
      dispatch(clearState());
    }
  },[isSuccess])

  return (
    <>
    {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="headerSmall withButton">
        <p className="HighlightHeading">
          <span className="parent">All Mentors </span>/ View Profile
        </p>
        <Button
          className={"imgButton GreenSlotButton rowStraight"}
          src={"/assets/icons/tick-circle.png"}
          onClick={handleSave}
          name={"Save Changes"}
        />
      </div>

      <div className="slotsContainer mt-10">
        <div className="datePickWrapper">
          <p className="heading">Select Date Range</p>
          <div className="dateRange">
            <input
              type={"date"}
              min={moment(new Date()).add(1,"day").format("YYYY-MM-DD")}
              value={dateRange.from}
              onChange={(e) => setDateRange({ from: e.target.value, to: "" })}
              onKeyDown={(e) => e.preventDefault()}
            />
            <p className="dateRangeTo">to</p>
            <input
              type={"date"}
              min={
                dateRange.from && moment(dateRange.from).format("YYYY-MM-DD")
              }
              value={dateRange.to}
              onChange={(e) =>
                setDateRange((prev) => ({ ...prev, to: e.target.value }))
              }
              onKeyDown={(e) => e.preventDefault()}
              
            />
          </div>
        </div>

        <div className="tabContent mt-15 active">
          <table className="weeklyTable" cellSpacing={0} cellPadding={0}>
            <tbody>
              {daysInAWeek.map((day) => (
                <tr>
                  <td>
                    <div className="togglerContainer">
                      <div
                        className={`toggler ${
                          selectedWeekDays.includes(day) ? "active" : ""
                        }`}
                        onClick={() => handleCheckboxToggle(day)}
                      >
                        <div className="togglerBtn"></div>
                      </div>
                    </div>
                  </td>
                  <td>{day.toUpperCase()}</td>
                  <td>
                    {selectedWeekDays.includes(day) ? (
                      <div className="timeSlots">
                        {timeRangeCount[day]?.map((j, i) => (
                          <div className="timeSlot">
                            <div className="">
                              <TimePicker
                                showSecond={false}
                                defaultValue={now}
                                value={interviewTime[day][i]?.from}
                                className="xxx"
                                onChange={(e) =>
                                  handleTimeFromChange(e, day, i)
                                }
                                format={format}
                                use12Hours
                                minuteStep={10}
                              />
                            </div>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.83325 8C2.83325 7.72386 3.05711 7.5 3.33325 7.5H12.6666C12.9427 7.5 13.1666 7.72386 13.1666 8C13.1666 8.27614 12.9427 8.5 12.6666 8.5H3.33325C3.05711 8.5 2.83325 8.27614 2.83325 8Z"
                                fill="#404040"
                              />
                            </svg>
                            <div className="">
                              <TimePicker
                                showSecond={false}
                                //   defaultValue={now}
                                value={interviewTime[day][i]?.to}
                                className="xxx"
                                onChange={(e) => handleTimeToChange(e, day, i)}
                                format={format}
                                use12Hours
                                minuteStep={10}
                              />
                            </div>
                            <div
                              style={{ marginLeft: "28px" }}
                              className="pointer"
                              onClick={(e) => handleTimeSlotRemove(day, i)}
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.75 4.48499C13.2525 4.23749 10.74 4.10999 8.235 4.10999C6.75 4.10999 5.265 4.18499 3.78 4.33499L2.25 4.48499"
                                  stroke="#EF5350"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6.375 3.7275L6.54 2.745C6.66 2.0325 6.75 1.5 8.0175 1.5H9.9825C11.25 1.5 11.3475 2.0625 11.46 2.7525L11.625 3.7275"
                                  stroke="#EF5350"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.1373 6.85501L13.6498 14.4075C13.5673 15.585 13.4998 16.5 11.4073 16.5H6.5923C4.4998 16.5 4.4323 15.585 4.3498 14.4075L3.8623 6.85501"
                                  stroke="#EF5350"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.74707 12.375H10.2446"
                                  stroke="#EF5350"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.125 9.375H10.875"
                                  stroke="#EF5350"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      "Unavailable"
                    )}
                  </td>
                  <td>
                    {selectedWeekDays.includes(day) && (
                      <div
                        className="pointer"
                        onClick={() => {
                          setTimeRangeCount({
                            ...timeRangeCount,
                            [day]: [...timeRangeCount[day], 0],
                          });
                          setInterviewTime({
                            ...interviewTime,
                            [day]: [
                              ...interviewTime[day],
                              { from: now, to: now },
                            ],
                          });
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.66602 10H13.3327"
                            stroke="#525252"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 13.3333V6.66666"
                            stroke="#525252"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.49935 18.3333H12.4993C16.666 18.3333 18.3327 16.6667 18.3327 12.5V7.49999C18.3327 3.33332 16.666 1.66666 12.4993 1.66666H7.49935C3.33268 1.66666 1.66602 3.33332 1.66602 7.49999V12.5C1.66602 16.6667 3.33268 18.3333 7.49935 18.3333Z"
                            stroke="#525252"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default EditSlots;
