import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let orgId;
let token;
let adminId;
let mentorId;

const superAdminRoute = "/api/mentorship/superAdmin/";
const mentorRoute = "/api/mentorship/mentor/";

let setTokenValues = () => {
    orgId = "50f2f27b-3cf1-4802-aef3-d422eee85be7"
    token = localStorage.getItem("token");
    // adminId = localStorage.getItem("adminId");
    mentorId = localStorage.getItem("mentorId");
};

//router.get("/:organizationId/mentor/:mentorId/getMentorDetails",mentorshipMentorController.getMentorDetails);
export const getMentorDetails = createAsyncThunk("admin/getMentorDetails", async ( thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            mentorRoute +
            orgId+
            "/mentor/"+
            mentorId+
            "/getMentorDetails",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getMentorInterviewSlots = createAsyncThunk("mentor/getMentorInterviewSlots", async (date, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            mentorRoute +
            orgId +
            "/mentor/" +
            mentorId + `/getMentorInterviewSlots?date=${date}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getUpcomingInterviewDetails = createAsyncThunk("mentor/getUpcomingInterviewDetails", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            mentorRoute +
            orgId +
            "/mentor/" +
            mentorId + `/getUpcomingInterviewDetails`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getPastInterviewDetails = createAsyncThunk("mentor/getPastInterviewDetails", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            mentorRoute +
            orgId +
            "/mentor/" +
            mentorId + `/getPastInterviewDetails`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const addAdminAvailableSlots = createAsyncThunk("admin/addAdminAvailableSlots", async (obj, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            mentorRoute +
            orgId +
            "/mentor/" +
            mentorId +
            "/addAdminAvailableSlots",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({...obj,mentorId})
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const saveMentorDetails = createAsyncThunk("admin/saveMentorDetails", async (obj, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            mentorRoute +
            mentorId +
            "/saveMentorDetails",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(obj)
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const changeStatus = createAsyncThunk("mentor/changeStatus", async ({bookingId, status,ongoingTime}, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(environment.baseURL +
            mentorRoute +
            "/booking/"+
            bookingId +
            "/changeStatus",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({status,ongoingTime})
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);



export const mentorSlice = createSlice({
    name: "mentor",
    initialState: {
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: "",
        MentorDetails: [],
        availableSlots:[],
        mentorUpcomingCalls: [],
        mentorPastCalls: [],
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        },
    },
    extraReducers: {
        [getMentorDetails.pending]: (state) => {
            state.isFetching = true;
        },
        [getMentorDetails.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.specificMentor = payload.Data;
            return state;
        },
        [getMentorDetails.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getMentorInterviewSlots.pending]: (state) => {
            state.isFetching = true;
        },
        [getMentorInterviewSlots.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.availableSlots = payload.Data;
            return state;
        },
        [getMentorInterviewSlots.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getUpcomingInterviewDetails.pending]: (state) => {
            state.isFetching = true;
        },
        [getUpcomingInterviewDetails.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.mentorUpcomingCalls = payload.Data;
            return state;
        },
        [getUpcomingInterviewDetails.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getPastInterviewDetails.pending]: (state) => {
            state.isFetching = true;
        },
        [getPastInterviewDetails.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.mentorPastCalls = payload.Data;
            return state;
        },
        [getPastInterviewDetails.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [addAdminAvailableSlots.pending]: (state) => {
            state.isFetching = true;
        },
        [addAdminAvailableSlots.fulfilled]: (state) => {
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [addAdminAvailableSlots.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [saveMentorDetails.pending]: (state) => {
            state.isFetching = true;
        },
        [saveMentorDetails.fulfilled]: (state) => {
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [saveMentorDetails.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [changeStatus.pending]: (state) => {
            state.isFetching = true;
        },
        [changeStatus.fulfilled]: (state) => {
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [changeStatus.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
    }
});


export const { clearState } = mentorSlice.actions;

export const mentorSliceData = (state) => state.mentor;